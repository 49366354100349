import React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { SignInMutation } from "core/mutations";
import SignInProviders from "core/components/SignInProviders";
import { getUtmFromLocalStorage } from "core/utils/utm";
import { useArena } from "core/components/ArenaProvider";
import ErrorScreen from "core/components/ErrorScreen";
import NoAccountsIcon from "@mui/icons-material/NoAccounts";

export default function SignInForm({ onCompleted, onError, email, setEmail }) {
  const { t } = useTranslation("core");
  const arena = useArena();

  const [signIn, { loading }] = useMutation(SignInMutation, {
    variables: {
      email,
      utm: getUtmFromLocalStorage(),
    },
    onCompleted,
    onError,
  });

  if (!arena.isRegistrationEnabled) {
    return (
      <ErrorScreen
        icon={NoAccountsIcon}
        title={t("signIn.registrationDisabled.title")}
        text={t("signIn.registrationDisabled.text")}
      />
    );
  }

  const onSubmit = (event) => {
    event.preventDefault();
    signIn();
  };

  const onChange = (event) => setEmail(event.target.value.trim());

  const isValid = !!email && email.includes("@") && email.includes(".");

  return (
    <Grid container spacing={4} component="form" onSubmit={onSubmit}>
      <Grid item xs={12}>
        <TextField
          autoFocus
          required
          fullWidth
          variant="outlined"
          label="Email"
          disabled={loading}
          helperText={t("signIn.emailText")}
          name="email"
          onChange={onChange}
        />
      </Grid>

      <Grid item xs={12}>
        <Button
          disableElevation
          component="button"
          type="submit"
          size="large"
          color="primary"
          variant="contained"
          fullWidth
          disabled={loading || !isValid}
        >
          {t("signIn.submit")}
        </Button>
      </Grid>

      <Grid item xs={12}>
        <SignInProviders />
      </Grid>
    </Grid>
  );
}

SignInForm.propTypes = {
  arena: PropTypes.shape({
    id: PropTypes.string,
    privacyPolicyUrl: PropTypes.string,
    termsOfServiceUrl: PropTypes.string,
    refundPolicyUrl: PropTypes.string,
    viewerCountry: PropTypes.string,
    arenaAuth: PropTypes.shape({
      id: PropTypes.string,
      google: PropTypes.shape({
        enabled: PropTypes.bool,
      }),
      apple: PropTypes.shape({
        enabled: PropTypes.bool,
      }),
    }),
  }).isRequired,
  email: PropTypes.string,
  setEmail: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onCompleted: PropTypes.func.isRequired,
};
